<script>
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { fetchPageBuilder } from '@drapejs/core';

import {validateEmail} from '../../utils.ts'

import MyPagesBack from './components/MyPagesBack.vue';
import ErrorMessage from '../forms/ErrorMessage.vue';
import PhoneInput from '../forms/PhoneInput'

import * as user from '../../../connectors/litium/user';

export default {
  components: {
    MyPagesBack,
    PhoneInput,
    ErrorMessage,
  },  
  emits: ['close-modal', 'back'],
  data() {
    return {
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      phoneNumber: '',
      phoneNumberError : '',
      newEmail: '',
      newEmailError:'',
      validationSent: false,
      savePersonalInfoError: '',
      saveNewEmailError: '',
      currentPassword: '',
      currentPasswordError: '',
      newPassword: '',
      newPasswordError: '',
      confirmPassword: '',
      confirmPasswordError: '',
      savePasswordError : '',
      savePasswordOk: '',
      requestPending: false
    };
  },
  computed: {
    currentEmailText() {
      return this.$replaceTokens(
            this.$globalTexts.mypages__current_email,
            {
              'email': this.$user.person.email,
            },
          );
    },
    hasPendingEmail() {
      return this.$user.person.emailUnverified;
    },
    pendingEmailText() {
      return this.$replaceTokens(
            this.$globalTexts.mypages__pending_email,
            {
              'email': this.$user.person.emailUnverified,
            },
          );
    },
    phoneCountry() {
      if(this.phoneNumber) {
        var prefix = this.phoneNumber.split(' ')[0].replace('+', '');
        var matchingPrefix = this.$globalFields?.PhonePrefixes.find((p) => p.prefix === prefix);

        if(matchingPrefix) {
          return matchingPrefix.country;
        }
      }

      return this.$cart.countryId;
    },
    passwordRegex() {
      return this.$globalFields?.PasswordPolicyRegex || "";
    },
  },  
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    clearError(error) {
      this[error] = '';
    },
    updatePhoneNumber(phoneNumber) {
      this.phoneNumber = phoneNumber;
      this.phoneNumberError = '';
    },
    validatePersonalInfo() {
      var hasErrors = false;
      if(!this.firstName) {
        this.firstNameError = this.$globalTexts.global__first_name_required;
        hasErrors = true;
      }

      if(!this.lastName) {
        this.lastNameError = this.$globalTexts.global__last_name_required;
        hasErrors = true;
      }

      const parsedNumber = parsePhoneNumberFromString(
        this.phoneNumber,
        this.phoneCountry,
      );

      if (!parsedNumber || !parsedNumber.isValid()) {
        this.phoneNumberError = this.$globalTexts.global__invalid_phone;
        hasErrors = true;
      }

      return hasErrors;
    },
    async savePersonalInfo() {
      
      this.savePersonalInfoError = '';
      var hasErrors = this.validatePersonalInfo();

      if(hasErrors) {
        return;
      }

      const parsedNumber = parsePhoneNumberFromString(
        this.phoneNumber,
        this.phoneCountry,
      );

      const requestArgs = this.buildCommandArgs({
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: `+${parsedNumber.countryCallingCode} ${parsedNumber.nationalNumber}`
      });

      const response = await this.executeCommand(
        user.commands.updatePerson,
        requestArgs,
      );

      if(!response || response.error === 'FAILED') {
        this.savePersonalInfoError = this.$globalTexts.global__save_error;
      }
    },
    async saveNewEmail() {

      if(!validateEmail(this.newEmail)) {
        this.newEmailError = this.$globalTexts.global__email_invalid;
        return;
      }

      await this.sendEmailValidation(this.newEmail);
    },
    async resendEmailValidation() {
      await this.sendEmailValidation(this.$user.person.emailUnverified)
    },
    async sendEmailValidation(email) {
      const requestArgs = this.buildCommandArgs({
        newEmail: email
      });

      const response = await this.executeCommand(
        user.commands.sendEmailVerification,
        requestArgs,
      );

      switch(response.error) {
        case 'FAILED':
          this.saveNewEmailError = this.$globalTexts.global__save_error;
          break;

        default:
          this.validationSent = true;
          
          var that = this;
          setTimeout(() => {
            that.validationSent = false;
          }, 2000);

      }
    },
    async saveNewPassword() {
      
      if(!this.currentPassword) {
        this.currentPasswordError = this.$globalTexts.global__required_field;
        return;
      }

      if(!this.newPassword) {
        this.newPasswordError = this.$globalTexts.global__required_field;
        return;
      }

      if(!this.confirmPassword) {
        this.confirmPasswordError = this.$globalTexts.global__required_field;
        return;
      }

      if (this.passwordRegex && !RegExp(this.passwordRegex).test(this.newPassword)) {
        this.newPasswordError = this.$globalTexts.mypages__new_password_complexity;
        return;
      }

      if(this.newPassword != this.confirmPassword) {
        this.confirmPasswordError = this.$globalTexts.mypages__repeat_password_missmatch;
        return;
      }

      const requestArgs = this.buildCommandArgs({
        oldPassword: this.currentPassword,
        newPassword: this.newPassword
      });

      const response = await this.executeCommand(
        user.commands.changePassword,
        requestArgs,
      );

      switch(response.changePassword) {
        case 'FAILED':
          this.savePasswordError = this.$globalTexts.global__save_error;
          break;

        case 'INVALID_PASSWORD':
          this.currentPasswordError = this.$globalTexts.mypages__current_password_invalid;
          break;

        case 'NONE':
          this.savePasswordOk = this.$globalTexts.mypages__password_changed;
          this.currentPassword = '';
          this.newPassword = '';
          this.confirmPassword = '';
        break;
      }
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          '',
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    }
  },
  created() {
    this.firstName = this.$user.person.firstName;
    this.lastName = this.$user.person.lastName;
    this.phoneNumber = this.$user.person.phone;
  }
};
</script>

<template>
  <div class="person-profile">
    
    <my-pages-back
      @back="onBack()"
      @exit="closeModal()"
    />
    
    <div class="person-profile__content">
      <div class="person-profile__title">
        {{ $globalTexts.mypages__profile }}
      </div>

      <div>
        <div class="person-profile__subtitle">{{ $globalTexts.mypages__general}}</div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="firstName">{{ $globalTexts.global__first_name}}</label>
          <input type="text" id="firstName" class="person-profile__input" v-model="firstName" @input="clearError('firstNameError')" />
          <error-message v-if="firstNameError" :message="firstNameError" />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="lastName">{{ $globalTexts.global__last_name}}</label>
          <input type="text" id="lastName" class="person-profile__input" v-model="lastName"  @input="clearError('lastNameError')" />
          <error-message v-if="lastNameError" :message="lastNameError" />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="phoneInput">{{ $globalTexts.global__phone}}</label>
          <phone-input
            class="person-profile__phone-input"
            :value="phoneNumber"
            :selected-country="$cart.countryId"
            :placeholder="$globalTexts.global__phone"
            :error-message="phoneNumberError"
            @input="updatePhoneNumber"
          />
        </div>

        <button class="secondary-button" @click="savePersonalInfo" :disabled="requestPending">{{ $globalTexts.global__save }}</button>
        <error-message v-if="savePersonalInfoError" :message="savePersonalInfoError" />
      </div>

      <div class="person-profile__section">
        <div class="person-profile__subtitle">{{ $globalTexts.global__email}}</div>

        <div class="person-profile__text" v-html="currentEmailText" />

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="newEmail">{{ $globalTexts.mypages__new_email}}</label>
          <input type="text" id="newEmail" class="person-profile__input" v-model="newEmail" />
          <error-message v-if="newEmailError" :message="newEmailError" />
          <div v-if="hasPendingEmail" >
            <span v-html="pendingEmailText" class="person-profile__label"></span> 
            <a href="" @click.prevent="resendEmailValidation" class="person-profile__label">({{ $globalTexts.mypages__resend_validation }})</a>
          </div>
        </div>

        <button class="secondary-button" @click="saveNewEmail" :disabled="requestPending">{{ $globalTexts.global__validate_email }}</button>
        <error-message v-if="saveNewEmailError" :message="saveNewEmailError" />
        <span class="person-profile__message" v-if="validationSent">{{ $globalTexts.mypages__new_email_validation }}</span>
      </div>

      <div class="person-profile__section person-profile__password-form">
        <div class="person-profile__subtitle">{{ $globalTexts.global__password}}</div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="currentPassword">{{ $globalTexts.mypages__current_password}}</label>
          <input type="password" id="currentPassword" class="person-profile__input" v-model="currentPassword"  @input="clearError('currentPasswordError')" />
          <error-message v-if="currentPasswordError" :message="currentPasswordError" />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="newPassword">{{ $globalTexts.mypages__new_password}}</label>
          <input type="password" id="newPassword" class="person-profile__input" v-model="newPassword" @input="clearError('newPasswordError')" />
          <error-message v-if="newPasswordError" :message="newPasswordError" />
        </div>

        <div class="person-profile__field-group">
          <label class="person-profile__label" for="repeatPassword">{{ $globalTexts.mypages__repeat_password}}</label>
          <input type="password" id="repeatPassword" class="person-profile__input" v-model="confirmPassword" @input="clearError('confirmPasswordError')"  />
          <error-message v-if="confirmPasswordError" :message="confirmPasswordError" />
        </div>

        <button class="secondary-button" @click="saveNewPassword" :disabled="requestPending">{{ $globalTexts.mypages__save_password }}</button>
        <error-message v-if="savePasswordError" :message="savePasswordError" />
        <span class="person-profile__message" v-if="savePasswordOk">{{ savePasswordOk }}</span>
      </div>
    </div>
    
  </div>
</template>

<style>
  .person-profile {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .person-profile__content {
    padding: 0px 1rem 1rem;
    height: 0;
  }

  .person-profile__section {
    padding-top: 15px;
  }

  .person-profile__title {
    font-family: Mulish;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
  }

  .person-profile__subtitle {
    font-family: Mulish;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #1A1A1A;
    margin: 14px 0;
  }

  .person-profile__field-group {
    margin-bottom: 14px; 
  }

  .person-profile__label {
    font-family: Mulish;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1A1A1A;
  }

  .person-profile__input {
    color: #333333;
  }

  .person-profile__text {
     font-family: Mulish;
     font-weight: 400;
     font-size: 14px;
     line-height: 19px;
     margin-bottom: 14px;
  }

  .person-profile__phone-input {
    flex: unset;
    width: 100%;
  }

  .person-profile__phone-input:nth-child(odd) {
    margin: 0 0 0.75rem 0;
  }

  .person-profile__message {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.18px;
    margin-top: 0.25rem;
    display: block;
  }

  .person-profile__password-form {
    padding-bottom: 2rem;
  }
</style>
